* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.MuiPickersLayout-root{
  position: relative;
}
.MuiPickersLayout-root .MuiPickersArrowSwitcher-root{
  top: 0px;
}
.MuiTimePickerToolbar-hourMinuteLabel{

}
.MuiTimePickerToolbar-hourMinuteLabel span{
  font-size: 2rem !important;
  line-height: 1.367 !important;
}

.MuiTimePickerToolbar-ampmSelection{
  position: absolute;
  bottom: 70px !important;
  margin: 0 !important;
  left: 0;
  right: 0;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 0px 12px;
  z-index: 10;
}

.MuiTimePickerToolbar-ampmSelection button span{
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTimePickerToolbar-ampmSelection button span.Mui-selected{
  background-color: #047FE0;
  color: #ffffff !important;
}

.MuiClock-wrapper .Mui-selected{
  color: #ffffff !important;
}

.MuiPickersLayout-actionBar{
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.MuiPickersLayout-actionBar button:first-child{
  color: #3F5C76 !important; 
}

.active-tab-btn{
  background-color: #047FE01A !important;
  color: #3F5C76 !important;
  border: 1px solid #047FE0 !important;
}