.ql-toolbar.ql-snow{
    border: none;
    padding: 0px;
}
.ql-container.ql-snow{
    border: none;
    border-bottom: 1px solid rgba(81, 95, 124, 0.25);
}
.ql-toolbar.ql-snow .ql-formats{
    display: flex;
}
.ql-snow.ql-toolbar button{
    height: 32px;
    max-width: 32px;
    flex: 0 0 32px;
    background-color: rgba(81, 95, 124, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    transition: all 0.25s ease-in-out;
}
.ql-snow.ql-toolbar button:hover {
    background-color: #E4E4E4;
}
.ql-snow.ql-toolbar button:hover .ql-stroke{
    stroke: #696969;
}
.ql-snow.ql-toolbar button:hover .ql-fill{
    fill: #696969;
}
.ql-snow.ql-toolbar button:hover .ql-stroke{
    stroke: #696969;
}
.ql-snow.ql-toolbar button.ql-active{
    background-color: #E4E4E4;
}
.ql-snow.ql-toolbar button .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke{
    stroke: #696969;
}
.ql-snow.ql-toolbar button .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill{
    fill: #696969;
}
.ql-snow.ql-toolbar button svg{
    height: 18px;
}
.ql-snow.ql-toolbar button:first-child{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.ql-snow.ql-toolbar button:last-child{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.ql-editor{
    padding: 0px;
    min-height: 48px;
    margin: 8px 0px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
}


